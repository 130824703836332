import { render, staticRenderFns } from "./TeachersUpdate.vue?vue&type=template&id=157fb068&scoped=true&"
import script from "./TeachersUpdate.vue?vue&type=script&lang=js&"
export * from "./TeachersUpdate.vue?vue&type=script&lang=js&"
import style0 from "./TeachersUpdate.vue?vue&type=style&index=0&id=157fb068&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "157fb068",
  null
  
)

export default component.exports